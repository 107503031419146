const PaymentMethodPage = () => import('./pages/PaymentMethodPage.vue');
const PaymentMethodUpdatePage = () => import('./pages/PaymentMethodUpdatePage.vue');

const routes = [
    {
        path: '/:key',
        name: 'payment.methods',
        component: PaymentMethodPage,
    },
    {
        path: '/update/:tenantId/:changeId',
        name: 'payment.methods.update',
        component: PaymentMethodUpdatePage,
    },
];

export default routes;
